<template>
  <div v-bind="$attrs" class="flex pvflex flex-row flex-nowrap" style="min-width: 15rem">
    <slot></slot>
    <div v-tooltip.right="hint" class="text-left" style="min-width: 10rem">
      {{ label }}
    </div>
    <!--    <PVButton v-tooltip.right="hint" outlined rounded text icon="material-icons help" class="uppercase nowrap" style="height:1.5rem"/>-->
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
  name: "ContactConfigSwitchComponent",
  props: {
    hint: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
  },
/*
  emits:['input'],
  data() {
    return {
      val: false
    }
  },
  methods: {
    emit(value) {
      if (value == null) {
        value = false;
      }
      this.$emit('input', value);
    }
  },*/
});
</script>
